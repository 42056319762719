"use strict";
// Copyright 2022 Fewcha. All rights reserved.
Object.defineProperty(exports, "__esModule", { value: true });
class Web3Provider {
    provider;
    constructor(provider) {
        this.provider = provider;
        return this;
    }
}
exports.default = Web3Provider;
