"use strict";
// Copyright 2022 Fewcha. All rights reserved.
Object.defineProperty(exports, "__esModule", { value: true });
exports.createReponse = void 0;
const createReponse = (method, status, data) => {
    return {
        method,
        status,
        data,
    };
};
exports.createReponse = createReponse;
