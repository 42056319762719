"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MartianMask = void 0;
const types_1 = require("./types");
class MartianMask {
    provider;
    constructor(provider) {
        this.provider = provider;
    }
    async generateTransaction(payload, options) {
        const response = await this.provider.connect();
        const sender = response.address;
        const res = await this.provider.generateTransaction(sender, payload);
        return (0, types_1.createReponse)("generateTransaction", 200, res);
    }
    async signTransaction(txnRequest) {
        const res = await this.provider.signTransaction(txnRequest);
        return (0, types_1.createReponse)("signTransaction", 200, res);
    }
    async submitTransaction(signedTxnRequest) {
        const res = await this.provider.submitTransaction(signedTxnRequest);
        return (0, types_1.createReponse)("submitTransaction", 200, res);
    }
    async signAndSubmitTransaction(txnRequest) {
        const res = await this.provider.signAndSubmitTransaction(txnRequest);
        return (0, types_1.createReponse)("signAndSubmit", 200, res);
    }
    async signMessage(message) {
        const res = await this.provider.signMessage(message);
        return (0, types_1.createReponse)("signMessage", 200, res);
    }
    async createCollection(name, description, uri, maxAmount) {
        const res = await this.provider.createCollection(name, description, uri, maxAmount);
        return (0, types_1.createReponse)("createCollection", 200, res);
    }
    async createToken(collectionName, name, description, supply, uri, max, royalty_payee_address, royalty_points_denominator, royalty_points_numerator, property_keys, property_values, property_types) {
        const res = await this.provider.createToken(collectionName, name, description, supply, uri, max, royalty_payee_address, royalty_points_denominator, royalty_points_numerator, property_keys, property_values, property_types);
        return (0, types_1.createReponse)("createToken", 200, res);
    }
    async getTransactions(query) {
        let res = null;
        if (query) {
            res = await this.provider.getTransactions(query);
        }
        else {
            res = await this.provider.provider.getTransactions();
        }
        return (0, types_1.createReponse)("getTransactions", 200, res);
    }
    async getTransactionByHash(txnHash) {
        const res = await this.provider.getTransaction(txnHash);
        return (0, types_1.createReponse)("getTransactionByHash", 200, res);
    }
    async getAccountTransactions(accountAddress, query) {
        const res = await this.provider.getAccountTransactions(accountAddress);
        return (0, types_1.createReponse)("getAccountTransactions", 200, res);
    }
    async getAccountResources(accountAddress, query) {
        const res = await this.provider.getAccountResources(accountAddress);
        return (0, types_1.createReponse)("getAccountResources", 200, res);
    }
    async getAccount(accountAddress) {
        const res = await this.provider.getAccount(accountAddress);
        return (0, types_1.createReponse)("getAccount", 200, res);
    }
    async getChainId() {
        const res = await this.provider.getChainId();
        return (0, types_1.createReponse)("getChainId", 200, res);
    }
    async getLedgerInfo() {
        const res = await this.provider.getLedgerInfo();
        return (0, types_1.createReponse)("getLedgerInfo", 200, res);
    }
}
exports.MartianMask = MartianMask;
